// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-grocerly-index-tsx": () => import("./../src/pages/grocerly/index.tsx" /* webpackChunkName: "component---src-pages-grocerly-index-tsx" */),
  "component---src-pages-grocerly-privacy-index-tsx": () => import("./../src/pages/grocerly/privacy/index.tsx" /* webpackChunkName: "component---src-pages-grocerly-privacy-index-tsx" */),
  "component---src-pages-grocerly-support-index-tsx": () => import("./../src/pages/grocerly/support/index.tsx" /* webpackChunkName: "component---src-pages-grocerly-support-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

